<template>
  <el-dialog title="SMS" width="50%" :visible.sync="showPopup" class="my-dialog-class" :close-on-click-modal='false'>
    <el-form label-position="top" :inline="false" :rules="rules" label-width="100%"  :model='selectedObject'  ref='my-form' @submit.prevent="save" v-loading="loadingVisible" >
      <el-row :gutter="15" >              
        <el-col :md='12' >
          <el-form-item label="Tip sms" prop="Tip" >            
            <el-input class='full-width' v-model='selectedObject.Tip' />
          </el-form-item>
        </el-col>
        <!-- <el-col :md='12' >
          <el-form-item label="Numere telefon" prop="NumereTelefon" >            
            <el-input class='full-width' v-model='selectedObject.NumereTelefon' />
          </el-form-item>
        </el-col> -->
        <el-col :md='8' >
          <el-form-item label="Activ" prop="EsteActiv" >            
            <el-checkbox v-model='selectedObject.EsteActiv' >DA</el-checkbox>
          </el-form-item>
        </el-col>
        <el-col>
          <el-form-item label='Continut' prop="Continut">
            <el-input type="textarea" :rows="4" v-model="selectedObject.Continut"></el-input>
          </el-form-item>
        </el-col>  
      </el-row>      
    </el-form> 
    <span slot="footer" class="dialog-footer">
      <el-button @click="resetForm('selectedObject')"           > Renunta  </el-button>
			<el-button type="primary" @click="save('selectedObject')" > Salveaza </el-button>
    </span>   
  </el-dialog>
</template>

<script>
import { VueEditor, Quill } from "vue2-editor"
import settings from "@/backend/LocalSettings"
import BasePage from '@/pages/BasePage'

export default {
    name: "SMS_template_dialog",
    extends: BasePage,
    components: {
      
    },
    data () {
      return {
        showPopup: false,
        mode: 'add',
        selectedObject: { NumereTelefon: '' , Continut: '', Tip: '', EsteActiv: false},                 
        Info:{ tipuri_sms: []},
        rules: {
          Name:     [{ required: true, message: 'Campul este obligatoriu', trigger: 'blur'  }],
          Continut: [{ required: true, message: 'Campul este obligatoriu' , trigger: 'blur' }],
          Tip:      [{ required: true, message: 'Campul este obligatoriu' , trigger: 'blur' }]
        }
      }
    },
    methods: {
        show_me: async function( id ) {
          this.selectedObject = { NumereTelefon: '' , Continut: '', Tip: '', EsteActiv: false}
            this.showPopup    = true;
            if( id == null )
            {
                this.mode = "add";
            }
            else
            {
                this.mode             = "edit";
                var            result = await this.post("sms/get_info_item_dialog", { id: id } );
                this.selectedObject   = result.Item;

                if(this.selectedObject.EsteActiv == '0')
                    this.selectedObject.EsteActiv = false
                if(this.selectedObject.EsteActiv == '1')
                    this.selectedObject.EsteActiv = true
            }          
        },
        async get_info(){
            var response          = await this.post("sms/get_info_for_dialog" );
            // this.Info.tipuri_sms = response.tipuri_sms
        },
        async save() {
          this.$refs['my-form'].validate( async(valid) => {
            if (valid)
            {
              await this.post("sms/save", { mode: this.mode, object: this.selectedObject } );
              this.resetForm()
              this.$emit("save");
            } else {console.log('eroare validare formular!');}
          });                
			},
			resetForm() {
				this.$refs['my-form'].resetFields()
				this.showPopup = false
			}
    },
    mounted: function() {
    //   this.get_info();
    },
    
};
</script>

<style lang="less" scoped>
.full-width {
  width: 100%;
}

</style>