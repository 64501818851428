<template>
    <base-page>
        <titlu-pagina Titlu="SMS" @on_add_clicked="show_dialog()" :AdaugaVisible='true' />
        <el-card style='margin:5px 0px 5px 0px'>
            <div slot="header" class="clearfix">
                <strong> Filtre </strong>
            </div>
            <div class="filtre">
                <el-form @submit.prevent.native='refresh_info()'>
                    <el-row :gutter="20">                        
                        <!-- <el-col :span='4'>
                            <el-form-item label='Nume'>
                                <el-input v-model='Filters.Name' />
                            </el-form-item>
                        </el-col>    -->
                        <el-col :span='4' >
                            <el-form-item label="Tip" > 
                                <el-select v-model="Filters.Tip" class="full-width">
                                    <el-option label="Toate" value="-1" ></el-option>
                                    <el-option v-for="(item,index) in Info.tipuri_sms" :key="index" :value="item" :label="item" ></el-option>
                                </el-select>
                            </el-form-item>
                        </el-col> 
                         <el-col :span='4' >
                            <el-form-item label="Este activ"  > 
                                <el-select v-model="Filters.EsteActiv" class="full-width">
                                    <el-option label="Toate" value="-1" ></el-option>
                                    <el-option value="1" label="Da" ></el-option>
                                    <el-option value="0" label="Nu" ></el-option>
                                </el-select>
                            </el-form-item>
                        </el-col> 
                        <el-col :md='24' >
                            <el-button type='primary' native-type='submit' @click='refresh_info()'> Aplica </el-button>
                            <el-button  @click='reset'> Reset </el-button>
                        </el-col>
                    </el-row>
                </el-form>
            </div> 
        </el-card>
       
        <el-table :data="Results" v-loading="loading">
            <el-table-column width="250px" prop='Tip' label='Tip sms'></el-table-column>
            <el-table-column prop='Continut' label='Continut'></el-table-column>
            <!-- <el-table-column prop='NumereTelefon' label='Numere Telefon'></el-table-column> -->
            <el-table-column width="100px" prop='EsteActiv' label='Activ'>
                <template slot-scope="scope">
                    <div v-if="scope.row.EsteActiv=='0'">NU</div>
                    <div v-else>DA</div>
                </template>
            </el-table-column>
            <el-table-column fixed="right" label="Actiuni" width="200px" >
                <template slot-scope="scope" >

                    <el-tooltip v-if="scope.row.EsteActiv=='0'" content="Activeaza" >
                        <el-button type="success" icon="el-icon-check" circle @click="activeaza(scope.row.Id)" />
                    </el-tooltip>

                    <el-tooltip v-if="scope.row.EsteActiv=='1'" content="Dezactiveaza" >
                        <el-button type="warning" icon="el-icon-close" circle @click="dezactiveaza(scope.row.Id)" />
                    </el-tooltip>

                    <el-tooltip content="Modificare">
                        <el-button type="primary" icon="el-icon-edit"  circle @click="show_dialog(scope.row.Id)" />
                    </el-tooltip>

                    <el-tooltip content="Sterge" >
                        <el-button type="danger" icon="el-icon-delete" circle @click="delete_item(scope.row)" />
                    </el-tooltip>
                    
                </template>
            </el-table-column>
            </el-table>
        <el-pagination @size-change="refresh_info" @current-change= "refresh_info" :page-size.sync="PaginationInfo.PerPage" :current-page.sync="PaginationInfo.Page" :total="PaginationInfo.RowCount" layout="pager" />
        <SMS-template-dialog ref='dlg' @save="refresh_info()" />
    </base-page>
</template>

<script>
import settings from "@/backend/LocalSettings";
import BasePage from "@/pages/BasePage";
import SMS_template_dialog from '@/pages/sms_template/SMS_template_dialog.vue';
import TitluPagina from '@/widgets/TitluPagina';

export default {
    name: "contracte",
    extends: BasePage,
    components: {
        'base-page': BasePage,
        'SMS-template-dialog': SMS_template_dialog,
        'titlu-pagina': TitluPagina,
    },
    data () {
        return {
            Results: [],
            base_url: '',
            Info: { tipuri_sms: [] },                            
            Filters: {Nume: '', Tip:'-1', Continut:'', NumereTelefon:'', EsteActiv:'-1'},                
            OrderBy: { },
            PaginationInfo: { Page: 1, PerPage: 50, RowCount: 0, PageSizes: [10, 25, 50, 100, 200] },
            loading: true
        }
    },
    methods: {
        async get_info(){
            var response = await this.post("sms/get_info" );
            this.Info.tipuri_sms = response.tipuri_sms
            this.refresh_info();
        },
        async refresh_info(){
            var response        = await this.post("sms/index", { Filters: this.Filters, OrderBy: this.OrderBy, PaginationInfo: this.PaginationInfo } );
            this.Results        = response.Results;
            this.PaginationInfo = response.PaginationInfo;
            this.loading        = false
            //
            this.select_menu_item('contracte');
        },
        reset(){
            this.Filters = {Nume: '', Tip:'-1', Continut:'', NumereTelefon:'', EsteActiv:'-1'};                
            this.refresh_info();
        },
        async delete_item( item ){
           this.$confirm(`Sunteti sigur ?`, 'Warning', {               
                type: 'warning'
                }).then(async() => {
                    await this.post("sms/delete_item", { id: item.Id } );
                    this.refresh_info(); 
                        this.$message({type: 'info', message: 'Stergere efectuata cu succes'});                                                                       
                        }).catch(() => {
                            this.$message({type: 'info', message: 'Stergere anulata'});                                                                 
                            });
        },
        show_dialog(id){
            this.$refs.dlg.show_me(id);
        },
        async activeaza(id){
            await this.post("sms/activeaza", { id: id } );
            this.refresh_info()
        },
        async dezactiveaza(id){
            await this.post("sms/dezactiveaza", { id: id } );
            this.refresh_info()
        }
    },
    mounted(){
        this.base_url = settings.BASE_URL;
        this.get_info();
    }
};
</script>

<style lang="less" scoped>

.top50{
  margin-top: 20px;
}

.filtre{
  .el-input-number
  {
    width: 100% !important;
  }
}

</style>
